@tailwind base;
@tailwind components;
@tailwind utilities;


.yes{
  font-family: 'Bricolage Grotesque', sans-serif;
}
.third{
  font-family: 'Bricolage Grotesque', sans-serif;
}
.shadow-inset-center {
	-webkit-animation: shadow-inset-center 0.4s linear both;
	        animation: shadow-inset-center 0.4s linear both;
}/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 3:30:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-inset-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
            box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
  }
}
@keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
            box-shadow: inset 0 0 14px 0px rgba(0, 0, 0, 0.5);
  }
}
.roll-in-blurred-left {
	-webkit-animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 5:17:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-left
 * ----------------------------------------
 */
 .container{
        height: 200px;
        width: 400px;
 }
 .image{
        max-width: 100%;
        max-height: 100%;
        
 }
 .box
{
  width: 600;
  height: auto;
  padding: 10;
  background-color: red;
}

.myimage{
        max-width: 100%;
}
.lo{
        font-family: 'Lobster', cursive;
}
.mo{
        font-family: 'Barlow', sans-serif;
}
.container {
        width: 100%;
        height: 100vh;
        background: url("https://images.pexels.com/photos/5543519/pexels-photo-5543519.jpeg?auto=compress&cs=tinysrgb&w=600") no-repeat;
        background-size: 100%;
    }
    .card-overlay {
        background: rgba(7, 5, 5, 0.5);
      }
      .lolo {
        background: rgba(76, 76, 76, 0.845);
      }
@font-face {
        font-family: 'Alisandra-Bold';
        src: url('C:\Users\abs\Downloads\alisandra\ali.ttf') format('truetype');
        /* Add other formats if available */
        font-weight: bold;
        font-style: bold;
      }
      /* Add this to your CSS file */
.scroll-to-top-button {
  position: fixed;
  right: 30px;
  bottom: 20px; /* Adjust the distance from the bottom as needed */
/* Adjust the distance from the left as needed */
  z-index: 1000; /* Ensure it's on top of other elements */
   /* Text color */
  cursor: pointer;
 
  font-size: 50px;
  transition: background-color 0.3s ease;
}



      
    .LazyLoad {
        opacity: 0;
        transition: all 1s ease-in-out;
      }
      .ro{
        font-family: 'Roboto Condensed', sans-serif;
      }
    .scale-on-hover {
        transition: transform 0.2s linear;
    }
    
    .scale-on-hover:hover {
        transform: scale(1.05); /* You can adjust the scaling factor as per your preference */
    }
    body{
        scroll-behavior: smooth;
    }
    .tappu{
        border-left-width: 20px;
        border-right-width: 20px;
        border-color: rgb(247, 255, 16)1, 255, 0);
    }
    
    
    
    
    
    

    .bapu:hover{
        transform: scale(1);
    }
    .po{
        
    }
    .jo{
        font-family: 'Inclusive Sans', sans-serif;
    }

 .no{
       
        font-family: 'Philosopher', sans-serif;
 }
 @-webkit-keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
*{
  scrollbar-width: thin;
  scrollbar-color: #397524 #DFE9EB;
 
}


/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #c5c5c5;
}

*::-webkit-scrollbar-track:active {
  background-color: #ffffff;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #eddc5c;
}
.bounce-in-top {
	-webkit-animation: bounce-in-top 1.2s both;
	        animation: bounce-in-top 1.2s both;
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.4s linear both;
	        animation: slide-in-right 0.4s linear both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 10:16:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
	-webkit-animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 10:18:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
.active-link{
  border-bottom: 4px solid rgb(142, 142, 23);
  border-radius: 2px;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-12 9:23:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */

 /* Customize Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  color: yellow; /* Set the button color to yellow */
  /* You can also add more styles like background-color, padding, etc. */
}

/* Customize Swiper pagination bullets */
.swiper-pagination-bullet {
  background-color: rgb(194, 194, 71); /* Set the bullet color to yellow */
  /* You can also add more styles like size, margin, etc. */
}

/* Customize Swiper pagination bullets when active */
.swiper-pagination-bullet-active {
  background-color: #ff9900; /* Set the active bullet color to a different shade of yellow */
  /* You can also add more styles like size, margin, etc. */
}

 @-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}


*::-webkit-scrollbar-thumb:hover {
  background-color: #e8d848;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #e1c857;
}
body{
  scroll-behavior: smooth;
  background: rgb(28, 28, 28);
}
/* Custom Slider Button Styles */
.custom-prev-button,
.custom-next-button {
  background-color: red; /* Change to your desired button color */
  color: white; /* Change to your desired text color */
  border-radius: 50%; /* Make the button circular */
  width: 40px; /* Adjust button width */
  height: 40px; /* Adjust button height */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust font size */
  transition: background-color 0.3s ease-in-out;
}

/* Hover styles (optional) */
.custom-prev-button:hover,
.custom-next-button:hover {
  background-color: darkred; /* Change to your desired hover color */
}

